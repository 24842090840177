import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CreditNoteProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getCreditNoteWarehouseById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/credit-notes/warehouses/${id}`)
  }

  getCreditNoteById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/credit-notes/${id}`)
  }
}

export default CreditNoteProvider
