<template>
  <div
    size="A4"
    class="credit-note">
    <div class="d-flex">
      <div class="w-haft">
        <div class="pt-2 pr-8">
          <div class="text-bold">
            ข้อมูลลูกค้า
          </div>
          <p class="mt-2">
            {{ data.address.address }}
            {{ data.address.address2 }}
            แขวง/ตำบล {{ data.address.subDistrict }}
            เขต/อำเภอ {{ data.address.district }}
            {{ data.address.province }}
            ประเทศ
            {{ data.address.country }} {{ data.address.postcode }}
          </p>
        </div>
      </div>
      <div class="w-haft text-left">
        <p class="credit-note-text">
          CREDIT NOTE
        </p>
        <tr>
          <th class="px-4 pt-1">
            เลขที่
          </th>
          <td class="px-4 pt-1">
            {{ data.id }}
          </td>
        </tr>
        <tr>
          <th class="px-4 pt-1">
            วันที่ทำรายการ
          </th>
          <td class="px-4 pt-1">
            {{ data.createdAt | dateTimeFormat() }}
          </td>
        </tr>
        <tr>
          <th class="px-4 pt-1">
            สินค้าทั้งหมด
          </th>
          <td class="px-4 pt-1">
            {{ amountItem }} รายการ
          </td>
        </tr>
        <tr>
          <th class="px-4 pt-1">
            Reference
          </th>
          <td class="px-4 pt-1">
            {{ data.orderRef.orderId }}
          </td>
        </tr>
      </div>
    </div>
    <div class="mt-4 product-table">
      <table style="width: 100%">
        <tr>
          <th class="px-4 py-1 table-head">
            ลำดับ
          </th>
          <th class="px-4 py-1 table-head">
            รายการสินค้า
          </th>
          <th class="px-4 py-1 table-head">
            จำนวน
          </th>
          <th class="px-4 py-1 table-head text-right">
            ราคา
          </th>
        </tr>
        <tr
          v-for="(sku, index) of data.skus"
          :key="index">
          <td class="px-4 py-1 text-center">
            {{ index + 1 }}
          </td>
          <td class="px-4 py-1">
            {{ sku.name }} ({{ sku.size }} - {{ sku.color }})
          </td>
          <td class="px-4 py-1 text-center">
            {{ sku.amount }}
          </td>
          <td class="px-4 py-1 text-right">
            {{ sku.price | showFullPriceFormat() }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-10 d-flex justify-end">
      <div class="summary">
        <div class="d-flex justify-space-between">
          <span class="text-bold">
            ค่าจัดส่ง
          </span>
          <span>
            {{ data.shippingFee | showFullPriceFormat() }}
          </span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="text-bold">
            ส่วนลด
          </span>
          <span>
            {{ data.discount | showFullPriceFormat() }}
          </span>
        </div>

        <div class="mt-4 d-flex justify-space-between">
          <span class="text-bold">
            ยอดรวมสุทธิ
          </span>
          <span>
            {{ data.net | showFullPriceFormat() }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreditNoteProvider from '@/resources/CreditNoteProvider'

const CreditNoteService = new CreditNoteProvider()

export default {
  data () {
    return {
      fetching: false,
      data: null
    }
  },
  computed: {
    cnId () {
      return this.$route.params.id || ''
    },
    amountItem () {
      if (!this.data) return 0
      return this.data.skus.reduce((sum, sku) => sum + sku.amount, 0)
    }
  },
  mounted () {
    this.fetchCreditNote()
  },
  methods: {
    async fetchCreditNote () {
      try {
        this.fetching = true
        const { data: cnw } = await CreditNoteService.getCreditNoteWarehouseById(this.cnId)
        if (!cnw) {
          throw new Error('Notfound')
        }
        const { data } = await CreditNoteService.getCreditNoteById(cnw.creditNote)
        this.data = data
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>

<style>
  .product-table {
    min-height: 560px;
  }
  .summary {
    padding: 12px;
    width: 300px;
    background-color: #f6f6f6;
  }
  .table-head {
    background-color: #f6f6f6;
    border-bottom: 1px solid #000;
  }
  .credit-note {
    background: #fff;
    width: 210mm;
    min-height: 297mm;
    display: block;
    margin: 40px auto;
    padding: 14mm 15mm;
    font-size: 14px;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    color: #000;
  }
  .w-haft {
    width: 50%;
  }
  .credit-note-text {
    font-size: 36px;
    font-weight: 600;
    text-align: right;
  }
  @media print {
    #sidebar {
      display: none !important;
    }
    .v-sheet.theme--light.v-toolbar.v-toolbar--dense.v-app-bar.v-app-bar--fixed {
      display: none !important;
    }
    .credit-note {
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
    }
  }
</style>
